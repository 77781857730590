var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__section" },
    [
      _c("ap-loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.warning,
            callback: function($$v) {
              _vm.warning = $$v
            },
            expression: "warning"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Warning\n      ")
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.warningMsg) +
                    " Are you sure you want to do this?\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1 white--text" },
                      on: {
                        click: function($event) {
                          _vm.warning = false
                        }
                      }
                    },
                    [_vm._v("\n          Cancel\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1 white--text" },
                      on: { click: _vm.generateSite }
                    },
                    [_vm._v("\n          Migrate\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ap__title" }, [
        _vm._v("\n    Generate Site\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ap__item" }, [
        _vm._v("\n    Generate a DDC Site on the Achilles Platform.\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("Account Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.data.domain,
                expression: "data.domain"
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.data.domain },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.data, "domain", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm._m(0)
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Destination Network")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.albs, label: "domain" },
              on: {
                input: function($event) {
                  _vm.updateDestSubdomains()
                  _vm.updateProfiles()
                }
              },
              model: {
                value: _vm.data.destination,
                callback: function($$v) {
                  _vm.$set(_vm.data, "destination", $$v)
                },
                expression: "data.destination"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Network to generate site in.\n      ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Profile Site")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.profiles, label: "domain" },
              model: {
                value: _vm.data.profile,
                callback: function($$v) {
                  _vm.$set(_vm.data, "profile", $$v)
                },
                expression: "data.profile"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Profile to use with site.\n      ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Prioritize Profile Pages")]),
            _vm._v(" "),
            _c("v-switch", {
              attrs: { color: "primary", value: "true", "hide-details": "" },
              model: {
                value: _vm.data.use_profile_common_pages,
                callback: function($$v) {
                  _vm.$set(_vm.data, "use_profile_common_pages", $$v)
                },
                expression: "data.use_profile_common_pages"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v(
                "\n        Use profile site's page if URL matched.\n      "
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("Site XML(s)")]),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "file", multiple: "", accept: ".xml" },
            on: { change: _vm.updateFiles }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("Account + Lang XLSX")]),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "file", accept: ".xlsx" },
            on: { change: _vm.updateFiles }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("Leads CSV")]),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "file", accept: ".csv" },
            on: { change: _vm.updateFiles }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ap__notification" }, [
        _vm._v("\n    " + _vm._s(_vm.message) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__wrap--btn" },
        [
          _c("ap-button", {
            attrs: { disabled: _vm.loading, value: "Generate Site" },
            on: { clicked: _vm.confirmGenerate }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__item" },
        [
          _c(
            "v-tabs",
            { attrs: { color: "white" } },
            _vm._l(_vm.tabs, function(tab) {
              return _c(
                "v-tab",
                {
                  key: tab.label,
                  attrs: { ripple: "" },
                  on: {
                    click: function($event) {
                      return _vm.changeActiveTab(tab)
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(tab.label) + "\n      ")]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ap__tab__content" },
            [_c("keep-alive", [_c(_vm.activeTab, { tag: "component" })], 1)],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ap__input__desc" }, [
      _vm._v("\n        Based on XML file name.\n        "),
      _c("br"),
      _vm._v("401dixiehyundaitc_en-CA.xml -> 401dixiehyundaitc\n      ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }