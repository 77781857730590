import { render, staticRenderFns } from "./showroomBPTable.vue?vue&type=template&id=488fda41&"
import script from "./showroomBPTable.vue?vue&type=script&lang=js&"
export * from "./showroomBPTable.vue?vue&type=script&lang=js&"
import style0 from "./showroomBPTable.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/achilles-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('488fda41')) {
      api.createRecord('488fda41', component.options)
    } else {
      api.reload('488fda41', component.options)
    }
    module.hot.accept("./showroomBPTable.vue?vue&type=template&id=488fda41&", function () {
      api.rerender('488fda41', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/components/panels/showroomBP/showroomBPTable.vue"
export default component.exports