var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__item" },
    [
      _c("ap-loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("div", { staticClass: "ap__title--sub" }, [
        _vm._v("\n      Build Query\n    ")
      ]),
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticClass: "white--text bold--text rounded-xl px-5 my-5",
          attrs: { color: "#343536" }
        },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "2" } }, [
                _c(
                  "div",
                  { staticClass: "ap__input__option" },
                  [
                    _c("v-select", {
                      attrs: { label: "network", options: _vm.albs },
                      on: { input: _vm.updateAccountList },
                      model: {
                        value: _vm.query.network,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "network", $$v)
                        },
                        expression: "query.network"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ap__input__desc font-italic" }, [
                      _vm._v(
                        "\n                    \tWhich network to query\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.hasErrors.query.network
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ap__input__desc font-italic error--text"
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\tPlease select a network\n\t\t\t\t\t"
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticStyle: { border: "1px solid #FFF" } },
            [
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "1" } }, [
                _c(
                  "div",
                  { staticClass: "ap__input__option" },
                  [
                    _c("v-input", { attrs: { readonly: "" } }, [
                      _c("strong", [_vm._v(" SELECT * FROM ")])
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "2" } }, [
                _c(
                  "div",
                  { staticClass: "ap__input__option" },
                  [
                    _c("v-select", {
                      attrs: { label: "label", options: _vm.tableNames },
                      model: {
                        value: _vm.query.table,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "table", $$v)
                        },
                        expression: "query.table"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ap__input__desc font-italic" }, [
                      _vm._v(
                        "\n                    \tWhich Table to Query\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.hasErrors.query.table
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ap__input__desc font-italic error--text"
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\tPlease select a table\n\t\t\t\t\t"
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "1" } }, [
                _c(
                  "div",
                  { staticClass: "ap__input__option" },
                  [
                    _c("v-input", { attrs: { readonly: "" } }, [
                      _c("strong", [_vm._v(" WHERE ")])
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.query.condition
                ? _c("v-col", { staticClass: "d-flex", attrs: { cols: "2" } }, [
                    _c(
                      "div",
                      { staticClass: "ap__input__option" },
                      [
                        _c("label"),
                        _vm._v(" "),
                        _c("v-text-field", {
                          attrs: {
                            solo: "",
                            dense: "",
                            "single-line": "",
                            label: "Enter column (eg: cfRecord)"
                          },
                          model: {
                            value: _vm.query.conditionColumnName,
                            callback: function($$v) {
                              _vm.$set(_vm.query, "conditionColumnName", $$v)
                            },
                            expression: "query.conditionColumnName"
                          }
                        }),
                        _vm._v(" "),
                        _vm.hasErrors.query.conditionColumnName
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ap__input__desc font-italic error--text"
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\tPlease enter column name\n\t\t\t\t\t"
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "2" } }, [
                _c(
                  "div",
                  { staticClass: "ap__input__option" },
                  [
                    _c("v-select", {
                      attrs: {
                        label: "label",
                        options: _vm.conditionOptions,
                        dense: "",
                        solo: ""
                      },
                      model: {
                        value: _vm.query.condition,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "condition", $$v)
                        },
                        expression: "query.condition"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ap__input__desc font-italic" }, [
                      _vm._v(
                        "\n                    \tSome condition (Optional)\n                    "
                      )
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.query.condition
                ? _c("v-col", { staticClass: "d-flex", attrs: { cols: "2" } }, [
                    _c(
                      "div",
                      { staticClass: "ap__input__option" },
                      [
                        _c("label"),
                        _vm._v(" "),
                        _c("v-text-field", {
                          attrs: {
                            solo: "",
                            dense: "",
                            "single-line": "",
                            label: "Enter value (eg: ghY56Tgf)"
                          },
                          model: {
                            value: _vm.query.conditionColumnValue,
                            callback: function($$v) {
                              _vm.$set(_vm.query, "conditionColumnValue", $$v)
                            },
                            expression: "query.conditionColumnValue"
                          }
                        }),
                        _vm._v(" "),
                        _vm.hasErrors.query.conditionColumnValue
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ap__input__desc font-italic error--text"
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\tPlease enter some value\n\t\t\t\t\t"
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "2" } }, [
                _c(
                  "div",
                  { staticClass: "ap__input__option" },
                  [
                    _c("label"),
                    _vm._v(" "),
                    _c("v-textarea", {
                      attrs: {
                        "single-line": "",
                        rows: "2",
                        flat: true,
                        label: "Any additional filter"
                      },
                      model: {
                        value: _vm.query.additionalFilters,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "additionalFilters", $$v)
                        },
                        expression: "query.additionalFilters"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ap__input__desc font-italic" },
                      [
                        _vm._v("\n\t\t\t\t\t\teg: LIMIT, ORDER BY, WHERE "),
                        _c(
                          "v-icon",
                          {
                            staticClass: "white--text",
                            attrs: { left: "" },
                            on: {
                              click: function($event) {
                                _vm.infoDialog = true
                              }
                            }
                          },
                          [_vm._v("fa fa-question-circle")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "1" } }, [
                _c(
                  "div",
                  { staticClass: "ap__input__option" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "success",
                          disabled: _vm.query.disabled
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.queryDatabase($event)
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("fa fa-check")
                        ]),
                        _vm._v(" Query ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "1" } }, [
                _c(
                  "div",
                  { staticClass: "ap__input__option" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "error" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.resetQuery($event)
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(" fa fa-ban")
                        ]),
                        _vm._v(" Reset ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "1" } }, [
                _c(
                  "div",
                  { staticClass: "ap__input__option" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: { click: _vm.openSaveQueryDialog }
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(" fa fa-save ")
                        ]),
                        _vm._v("\n\t\t\t\t\tSave ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("h4", { staticClass: "text-xl-h4" }, [_vm._v("OR")]),
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticClass: "white--text bold--text rounded-xl px-5 my-5",
          attrs: { color: "#343536" }
        },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "2" } }, [
                _c(
                  "div",
                  { staticClass: "ap__input__option" },
                  [
                    _c("v-select", {
                      attrs: { label: "name", options: _vm.savedQueries },
                      model: {
                        value: _vm.useSavedQuery,
                        callback: function($$v) {
                          _vm.useSavedQuery = $$v
                        },
                        expression: "useSavedQuery"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ap__input__desc font-italic" }, [
                      _vm._v(
                        "\n                    \tUse from saved queries\n                    "
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.results,
              search: _vm.search
            },
            scopedSlots: _vm._u([
              {
                key: "item.account",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "http://" + item.account,
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.account) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ap__table__footer" },
            [
              _c("ap-button", {
                attrs: { disabled: _vm.loading, value: "Download CSV" },
                on: { clicked: _vm.csv }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ap__notification", class: _vm.updated }),
      _vm._v(_vm._s(_vm.message) + "\n\t\n\t"),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("\n            Save Query\n          ")
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "ap__input__option" },
                  [
                    _c("label"),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        "single-line": true,
                        outlined: true,
                        label: "Enter query title"
                      },
                      model: {
                        value: _vm.saveQueryName,
                        callback: function($$v) {
                          _vm.saveQueryName = $$v
                        },
                        expression: "saveQueryName"
                      }
                    }),
                    _vm._v(" "),
                    _vm.hasErrors.saveQueryName
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ap__input__desc font-italic error--text"
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\tPlease enter some title\n\t\t\t\t"
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.saveQuery }
                    },
                    [_vm._v("\n              Save\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("\n              Close\n            ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.infoDialog,
            callback: function($$v) {
              _vm.infoDialog = $$v
            },
            expression: "infoDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
                _vm._v("\n          \t\tSQL Syntax\n        \t")
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-alert",
                    { staticClass: "my-2", attrs: { dense: "", type: "info" } },
                    [
                      _vm._v(
                        "\n\t\t\t\tAlways put string values in single quotes, like 'John Doe', 'Canada', 'SOMETOKEN'\n\t\t\t"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { "two-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Add extra where clause")
                          ]),
                          _vm._v(" "),
                          _c("v-list-item-subtitle", [
                            _vm._v("eg: WHERE name = 'John Doe'")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { "two-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Add multiple where clause")
                          ]),
                          _vm._v(" "),
                          _c("v-list-item-subtitle", [
                            _vm._v("eg: WHERE name = 'John Doe' AND age > 30")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { "two-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("To get only 100 records maximum")
                          ]),
                          _vm._v(" "),
                          _c("v-list-item-subtitle", [_vm._v("eg: LIMIT 100")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { "two-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("To sort result using user_name column")
                          ]),
                          _vm._v(" "),
                          _c("v-list-item-subtitle", [
                            _vm._v("eg: ORDER by user_name ASC")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { "two-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Use where, limit and order by togther")
                          ]),
                          _vm._v(" "),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              "eg: WHERE name = 'John Doe' AND age > 30 ORDER by user_name ASC LIMIT 100"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function($event) {
                          _vm.infoDialog = false
                        }
                      }
                    },
                    [_vm._v("\n              Close\n            ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }