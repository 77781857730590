var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "output", class: { disabled: _vm.section.disabled } },
    [
      _vm.section.disabled
        ? _c("div", { staticClass: "lds-dual-ring" })
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.section.output).length === 0
        ? _c("div", [
            _c("i", [_vm._v("N/A ( Hit the Validate File(s) button above! )")])
          ])
        : _c("div", [
            _c("div", { staticClass: "output-tabs" }),
            _vm._v(" "),
            _c("div", { staticClass: "output-tables" }, [
              Object.keys(_vm.section.results).length !== 0
                ? _c(
                    "div",
                    { staticClass: "overview" },
                    [
                      _c("div", { staticClass: "ap__label" }, [
                        _vm._v("\n            Overview\n          ")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.section.results, function(value, key) {
                        return _c(
                          "div",
                          { key: key, staticClass: "overview" },
                          [
                            _c("b", [_vm._v(_vm._s(key))]),
                            _vm._v(" : " + _vm._s(value) + "\n          ")
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }