var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c("div", { staticClass: "sidebar__title" }, [
        _vm._v("\n    Achilles Portal\n  ")
      ]),
      _vm._v(" "),
      _vm._l(_vm.panels, function(p) {
        return _c(
          "div",
          {
            key: p.component,
            staticClass: "sidebar__tab",
            class: { active: _vm.activePanel == p.component },
            on: {
              click: function($event) {
                return _vm.$store.commit("setActivePanel", p.component)
              }
            }
          },
          [_c("i", { class: p.icon }), _vm._v(_vm._s(p.label) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _c("ap-version")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }