var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__section" },
    [
      _c("ap-loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("div", { staticClass: "ap__title" }, [_vm._v("\n    Logging\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__item" },
        [
          _c("div", { staticClass: "ap__title--sub" }, [
            _vm._v("\n      Log Hours\n    ")
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__wrap" }, [
            _c("div", { staticClass: "ap__input" }, [
              _c("label", [_vm._v("Email")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.email,
                    expression: "data.email"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.data.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.data, "email", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ap__input__desc" }, [
                _vm._v("\n          Your Convertus email.\n        ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input" }, [
              _c("label", [_vm._v("API Token")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.token,
                    expression: "data.token"
                  }
                ],
                attrs: { type: "password" },
                domProps: { value: _vm.data.token },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.data, "token", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ap__input__desc" }, [
                _vm._v("\n          Your generated API Token.\n        ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input" }, [
              _c("label", [_vm._v("Type")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selected,
                      expression: "selected"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selected = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "Month" } }, [
                    _vm._v("\n            Month\n          ")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Day Range" } }, [
                    _vm._v("\n            Day Range\n          ")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ap__input__desc" }, [
                _vm._v("\n          How hours should be logged.\n        ")
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.selected === "Month"
            ? _c(
                "div",
                { staticClass: "ap__input__wrap" },
                [
                  _c("v-date-picker", {
                    attrs: {
                      "no-title": "",
                      min: "2019-01",
                      "show-current": false,
                      max: new Date().toISOString().substring(0, 7),
                      type: "month",
                      color: "#78be20"
                    },
                    model: {
                      value: _vm.data.month,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "month", $$v)
                      },
                      expression: "data.month"
                    }
                  })
                ],
                1
              )
            : _c("div", { staticClass: "ap__input__wrap" }, [
                _c(
                  "div",
                  { staticClass: "ap__input" },
                  [
                    _c("label", [_vm._v("Start")]),
                    _vm._v(" "),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "input",
                                  _vm._g(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.start,
                                          expression: "data.start"
                                        }
                                      ],
                                      attrs: { readonly: "" },
                                      domProps: { value: _vm.data.start },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "start",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    },
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(" "),
                        _c("v-date-picker", {
                          attrs: {
                            "no-title": "",
                            "show-current": false,
                            color: "#78be20"
                          },
                          model: {
                            value: _vm.data.start,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "start", $$v)
                            },
                            expression: "data.start"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ap__input" },
                  [
                    _c("label", [_vm._v("End")]),
                    _vm._v(" "),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "input",
                                  _vm._g(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.end,
                                          expression: "data.end"
                                        }
                                      ],
                                      attrs: { readonly: "" },
                                      domProps: { value: _vm.data.end },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "end",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    },
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(" "),
                        _c("v-date-picker", {
                          attrs: {
                            "no-title": "",
                            "show-current": false,
                            min: _vm.data.start,
                            color: "#78be20"
                          },
                          model: {
                            value: _vm.data.end,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "end", $$v)
                            },
                            expression: "data.end"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__wrap" }, [
            _c(
              "div",
              { staticClass: "ap__input" },
              [
                _c("label", [_vm._v("Task")]),
                _vm._v(" "),
                _c("v-select", {
                  attrs: { options: _vm.issues, label: "label", taggable: "" },
                  model: {
                    value: _vm.task,
                    callback: function($$v) {
                      _vm.task = $$v
                    },
                    expression: "task"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ap__input__desc" }, [
                  _vm._v(
                    "\n          Task to log hours into. Issues can be typed in.\n        "
                  )
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ap__notification", class: _vm.nclass }, [
            _vm._v("\n      " + _vm._s(_vm.message) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ap__wrap--btn" },
            [
              _c("ap-button", {
                attrs: { disabled: _vm.loading, value: "Log Hours" },
                on: { clicked: _vm.logHours }
              }),
              _vm._v(" "),
              _c("ap-button", {
                attrs: { disabled: _vm.loading, value: "Refresh Tasks" },
                on: { clicked: _vm.update }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("ap-response", { attrs: { json: _vm.output } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "This tool uses Jira's REST API to automatically log hours over a span of days or for a selected month."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n        An email and API token must be provided to use this tool. API tokens can be generated by logging into Jira and creating a token "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://id.atlassian.com/manage/api-tokens",
              target: "_blank"
            }
          },
          [_vm._v("here")]
        ),
        _vm._v(".\n      ")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "The script will take existing logs into account, adding hours so that each day has a total of 7 hours logged."
        )
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Weekends are skipped.")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("May take a while if hours are being added to many days.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }