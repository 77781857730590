var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__item" },
    [
      _c("ap-loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("div", { staticClass: "ap__title--sub" }, [
        _vm._v("\n    Buckets\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ap__notification", class: _vm.updated }),
      _vm._v(_vm._s(_vm.message) + "\n  "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Oem")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.oemNames, label: "OEM" },
              on: {
                input: function($event) {
                  return _vm.oemChanged(_vm.selectedOEM)
                }
              },
              model: {
                value: _vm.selectedOEM,
                callback: function($$v) {
                  _vm.selectedOEM = $$v
                },
                expression: "selectedOEM"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Select the OEM.\n      ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("New OEM")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newOEM,
                expression: "newOEM"
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.newOEM },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newOEM = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__desc" }, [
            _vm._v("\n        New OEM value.\n      ")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Regions")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.displayableRegions, label: "Region" },
              model: {
                value: _vm.selectedRegion,
                callback: function($$v) {
                  _vm.selectedRegion = $$v
                },
                expression: "selectedRegion"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Select the region.\n      ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("New Region")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newRegion,
                expression: "newRegion"
              }
            ],
            attrs: { disabled: _vm.disableTextBoxes, type: "text" },
            domProps: { value: _vm.newRegion },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newRegion = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__desc" }, [
            _vm._v("\n        New Region value.\n      ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__wrap--btn" },
        [
          _c("ap-button", {
            attrs: { disabled: _vm.disableAddOEM, value: "Add new OEM" },
            on: { clicked: _vm.addOEM }
          }),
          _vm._v(" "),
          _c("ap-button", {
            attrs: {
              disabled: _vm.disableAddRegion,
              value: "Add new region to OEM"
            },
            on: { clicked: _vm.addRegion }
          }),
          _vm._v(" "),
          _c("ap-button", {
            attrs: {
              disabled: _vm.disableDeleteRegion,
              value: "Delete region"
            },
            on: { clicked: _vm.deleteRegionDialog }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Makes")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.displayableMakes, label: "Make" },
              model: {
                value: _vm.selectedMake,
                callback: function($$v) {
                  _vm.selectedMake = $$v
                },
                expression: "selectedMake"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Select the Make.\n      ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("New Make")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newMake,
                expression: "newMake"
              }
            ],
            attrs: { disabled: _vm.disableTextBoxes, type: "text" },
            domProps: { value: _vm.newMake },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newMake = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__desc" }, [
            _vm._v("\n        New Make value.\n      ")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Order Makes")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                options: _vm.displayableMakes,
                label: "Select domains to add",
                multiple: ""
              },
              on: { input: _vm.makesOrderChange },
              model: {
                value: _vm.makesOrder,
                callback: function($$v) {
                  _vm.makesOrder = $$v
                },
                expression: "makesOrder"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v(
                "\n        Specify the order of the makes in the OEM.\n      "
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__wrap--btn" },
        [
          _c("ap-button", {
            attrs: {
              disabled: _vm.disableAddMake,
              value: "Add new make to OEM"
            },
            on: { clicked: _vm.addMake }
          }),
          _vm._v(" "),
          _c("ap-button", {
            attrs: { disabled: _vm.disableDeleteMake, value: "Delete Make" },
            on: { clicked: _vm.deleteMakeDialog }
          }),
          _vm._v(" "),
          _c("ap-button", {
            attrs: { disabled: _vm.makesOrderChanged, value: "Re-Order Makes" },
            on: { clicked: _vm.reOrderMakes }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Language: ")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.languages, label: "Language" },
              model: {
                value: _vm.selectedLanguage,
                callback: function($$v) {
                  _vm.selectedLanguage = $$v
                },
                expression: "selectedLanguage"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Select the language.\n      ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Size: ")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.sizes, label: "size" },
              model: {
                value: _vm.selectedSize,
                callback: function($$v) {
                  _vm.selectedSize = $$v
                },
                expression: "selectedSize"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Select the size.\n      ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Domains")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.domains, label: "domain", multiple: "" },
              model: {
                value: _vm.selectedDomains,
                callback: function($$v) {
                  _vm.selectedDomains = $$v
                },
                expression: "selectedDomains"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Select the domain.\n      ")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__wrap--btn" },
        [
          _c("ap-button", {
            attrs: { disabled: _vm.disableAddBucket, value: "Add new Bucket" },
            on: { clicked: _vm.addBucket }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ap__title--sub" }, [
        _vm._v("\n    Edit Buckets Domains\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Select Buckets: ")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.bucketNames, label: "Language" },
              on: { input: _vm.bucketSelected },
              model: {
                value: _vm.selectedBucket,
                callback: function($$v) {
                  _vm.selectedBucket = $$v
                },
                expression: "selectedBucket"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Select the bucket.\n      ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("To Remove Domains")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                options: _vm.bucketDomains,
                label: "Select domains to remove.",
                multiple: ""
              },
              model: {
                value: _vm.removedDomains,
                callback: function($$v) {
                  _vm.removedDomains = $$v
                },
                expression: "removedDomains"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v(
                "\n        Selected domains will be removed from the bucket.\n      "
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Add Domains")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                options: _vm.notAddedDomian,
                label: "Select domains to add",
                multiple: ""
              },
              model: {
                value: _vm.newDomains,
                callback: function($$v) {
                  _vm.newDomains = $$v
                },
                expression: "newDomains"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v(
                "\n        Selected domains will be added to the bucket.\n      "
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__wrap--btn" },
        [
          _c("ap-button", {
            attrs: {
              disabled: _vm.disableUpdateBucketDomains,
              value: "Update bucket domains"
            },
            on: { clicked: _vm.updateBucketDomain }
          }),
          _vm._v(" "),
          _c("ap-button", {
            attrs: { disabled: _vm.disableDelete, value: "Deactivate bucket" },
            on: { clicked: _vm.deleteBucketDialog }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showConfirmationDialog,
            callback: function($$v) {
              _vm.showConfirmationDialog = $$v
            },
            expression: "showConfirmationDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Delete/Deactivate Item")]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n        Are you sure you want to delete this item?\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "v-card-actions",
                    [
                      _vm.deleteRegionSelected
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.deleteRegion }
                            },
                            [_vm._v("\n            Yes, Delete\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deleteMakeSelected
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.deleteMake }
                            },
                            [_vm._v("\n            Yes, Delete\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deleteBucketSelected
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.deleteBucket }
                            },
                            [_vm._v("\n            Yes, Delete\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("\n            Cancel\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }