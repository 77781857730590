var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__section" },
    [
      _c("div", { staticClass: "ap__title" }, [
        _vm._v("\n      SSL Cloudflare\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__item" },
        [
          _c(
            "v-tabs",
            { attrs: { color: "white" } },
            _vm._l(_vm.tabs, function(tab) {
              return _c(
                "v-tab",
                {
                  key: tab.label,
                  attrs: { ripple: "" },
                  on: {
                    click: function($event) {
                      return _vm.changeActiveTab(tab)
                    }
                  }
                },
                [_vm._v("\n          " + _vm._s(tab.label) + "\n        ")]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ap__tab__content" },
            [_c("keep-alive", [_c(_vm.activeTab, { tag: "component" })], 1)],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__item", staticStyle: { position: "relative" } },
        [
          _c("ap-loading", { attrs: { loading: _vm.table.loading } }),
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "div",
                { staticClass: "ap__nav" },
                [
                  _c("div", [
                    _vm._v("Last Updated By Cron: "),
                    _c("strong", [_vm._v(_vm._s(_vm.lastUpdated))])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("Total Domains: "),
                    _c("strong", [_vm._v(_vm._s(_vm.newSslDomains.length))])
                  ]),
                  _vm._v(" "),
                  _c("ap-response", { attrs: { json: _vm.output } })
                ],
                1
              ),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c(
                "v-card-title",
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "search",
                      label: "Search",
                      "single-line": "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _vm._v(" "),
                  _c("ap-button", {
                    attrs: { disabled: _vm.table.loading, value: "Refresh" },
                    on: { clicked: _vm.refresh }
                  }),
                  _vm._v(" "),
                  _vm.table.filter.group === "pending"
                    ? _c("ap-button", {
                        attrs: {
                          disabled: _vm.table.loading,
                          value: "Update All"
                        },
                        on: {
                          clicked: function($event) {
                            return _vm.cloudflare({
                              method: "get",
                              url: "/cloudflare/trigger-cron"
                            })
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("label", { staticStyle: { margin: "0 5px" } }, [
                    _vm._v("Group")
                  ]),
                  _vm._v(" "),
                  _c("v-select", {
                    attrs: { options: _vm.cloudflareGroups, label: "name" },
                    model: {
                      value: _vm.table.filter.group,
                      callback: function($$v) {
                        _vm.$set(_vm.table.filter, "group", $$v)
                      },
                      expression: "table.filter.group"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-data-table", {
                attrs: {
                  headers: _vm.domainHeaders,
                  items: _vm.filtered,
                  search: _vm.search
                },
                scopedSlots: _vm._u([
                  {
                    key: "body",
                    fn: function(ref) {
                      var items = ref.items
                      return [
                        _c(
                          "tbody",
                          _vm._l(items, function(item, index) {
                            return _c("tr", { key: item.name }, [
                              _c(
                                "td",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        color: _vm.getFinalDomainStatus(item)
                                      }
                                    },
                                    [_vm._v("fa fa-circle ")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", [
                                !item.beingEdited
                                  ? _c("div", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t" +
                                          _vm._s(item.accountId || "N/A") +
                                          " \n\t\t\t\t\t\t"
                                      ),
                                      _c("i", {
                                        staticClass: "fa fa-pencil-alt",
                                        on: {
                                          click: function($event) {
                                            return _vm.editField(
                                              item.name,
                                              "beingEdited",
                                              true
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  : _c("div", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.newAccountId,
                                            expression: "newAccountId"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: "New Account ID"
                                        },
                                        domProps: { value: _vm.newAccountId },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.newAccountId =
                                              $event.target.value
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "ap__button",
                                          on: {
                                            click: function($event) {
                                              return _vm.updateDomain(item.name)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-check"
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "ap__button red",
                                          on: {
                                            click: function($event) {
                                              return _vm.editField(
                                                item.name,
                                                "beingEdited",
                                                false
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-times"
                                          })
                                        ]
                                      )
                                    ])
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(item.name))]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.getNetworkName(item)))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.getName("apexSSL", item)))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.getValue("apexSSL", item)))
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class:
                                    _vm.getStatusClass("apexSSL", item) +
                                    "--text"
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.getStatus("apexSSL", item)) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.getName("wwwSSL", item)))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.getValue("wwwSSL", item)))
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class:
                                    _vm.getStatusClass("wwwSSL", item) +
                                    "--text"
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.getStatus("wwwSSL", item)) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getName("ownershipVerification", item)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getValue("ownershipVerification", item)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class:
                                    _vm.getStatusClass(
                                      "ownershipVerification",
                                      item
                                    ) + "--text"
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.getStatus(
                                          "ownershipVerification",
                                          item
                                        )
                                      ) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.getCloudflareCustomOrigin(item))
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("i", {
                                  staticClass: "fa fa-minus-circle",
                                  attrs: { "aria-hidden": "true" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.deleteDomain(
                                        "/cloudflare/delete-domain",
                                        item
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          }),
                          0
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ap__table__footer" },
                [
                  _c("ap-button", {
                    attrs: { disabled: _vm.loading, value: "Download CSV" },
                    on: { clicked: _vm.csv }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ap__notification", class: _vm.updated }, [
            _vm._v("\n        " + _vm._s(_vm.message) + "\n      ")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("vue-confirm-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }