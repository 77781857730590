var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c("ap-loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("p", [_vm._v("\n    Manually renew group certificates here.\n  ")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("SSL Group")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.filteredGroups },
              model: {
                value: _vm.group,
                callback: function($$v) {
                  _vm.group = $$v
                },
                expression: "group"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Which group to renew.\n      ")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__wrap--btn" },
        [
          _c("ap-button", {
            attrs: { disabled: _vm.loading, value: "Renew Certificate" },
            on: { clicked: _vm.renewGroupCert }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("ap-response", { attrs: { json: _vm.output } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", [
      _c("li", [
        _vm._v(
          "Ensure the correct certificate is selected before running the renewal, as all domains within it will become pending."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Domains that are pending for too long will be removed from the group. The certificate will then be re-requested automatically without these domains."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }