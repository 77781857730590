var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__item" },
    [
      _c("v-data-table", {
        attrs: { headers: _vm.headers, items: _vm.sites },
        scopedSlots: _vm._u(
          [
            {
              key: "item.created",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.dateCreated(item.created)) +
                      "\n    "
                  )
                ]
              }
            },
            {
              key: "item.time",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.time(item.start, item.end)) +
                      "\n    "
                  )
                ]
              }
            },
            {
              key: "item.link",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        href:
                          item.destination.prefix +
                          "://" +
                          item.account +
                          "." +
                          item.destination.domain,
                        target: "_blank"
                      }
                    },
                    [_vm._v("\n        Link\n      ")]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }