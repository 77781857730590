var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "home" } },
    [
      _c("convertus-sidebar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-wrap" },
        [
          _c("div", { staticClass: "nav" }, [
            _c("div", { staticClass: "nav__wrap" }, [
              _c(
                "div",
                { staticClass: "nav__item", on: { click: _vm.logout } },
                [_vm._v("\n          Logout\n        ")]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "keep-alive",
            [_c(_vm.$store.state.activePanel, { tag: "component" })],
            1
          ),
          _vm._v(" "),
          _c("a", {
            staticStyle: { display: "none" },
            attrs: { id: "download", href: "#" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }