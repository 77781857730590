var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__item" },
    [
      _c("ap-loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("div", { staticClass: "ap__title--sub" }, [
        _vm._v("\n    Slides\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ap__notification", class: _vm.updated }),
      _vm._v(" "),
      _c("div", [_vm._v("\n    " + _vm._s(_vm.message) + "\n  ")]),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Oem")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.oemNames, label: "OEM" },
              on: {
                input: function($event) {
                  return _vm.oemChanged(_vm.selectedOEM)
                }
              },
              model: {
                value: _vm.selectedOEM,
                callback: function($$v) {
                  _vm.selectedOEM = $$v
                },
                expression: "selectedOEM"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Select the OEM.\n      ")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-tabs",
        {
          attrs: { color: "white" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _vm._l(_vm.tabs, function(item) {
            return _c("v-tab", { key: item }, [
              _vm._v("\n      " + _vm._s(item) + "\n    ")
            ])
          }),
          _vm._v(" "),
          _c("v-tab-item", [
            _c("div", { staticClass: "ap__input__wrap" }, [
              _c(
                "div",
                { staticClass: "ap__input" },
                [
                  _c("label", [_vm._v("From Buckets: ")]),
                  _vm._v(" "),
                  _c("v-select", {
                    attrs: { options: _vm.allBucketNames, label: "Language" },
                    on: { input: _vm.fromChanged },
                    model: {
                      value: _vm.fromBucket,
                      callback: function($$v) {
                        _vm.fromBucket = $$v
                      },
                      expression: "fromBucket"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ap__input__desc" }, [
                    _vm._v("\n            Select source bucket.\n          ")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ap__input" },
                [
                  _c("label", [_vm._v("To Buckets: ")]),
                  _vm._v(" "),
                  _c("v-select", {
                    attrs: { options: _vm.toBucketNames, label: "Language" },
                    model: {
                      value: _vm.toBucket,
                      callback: function($$v) {
                        _vm.toBucket = $$v
                      },
                      expression: "toBucket"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ap__input__desc" }, [
                    _vm._v(
                      "\n            Select destination bucket.\n          "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ap__wrap--btn" },
                [
                  _c("ap-button", {
                    attrs: {
                      disabled: _vm.disableMigrateSlides,
                      value: "Migrate Slides"
                    },
                    on: { clicked: _vm.displayMigrationDialog }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "ap__input" },
              [
                _c("label", [_vm._v("Buckets")]),
                _vm._v(" "),
                _c("v-select", {
                  attrs: { options: _vm.activeBucketNames, label: "Bucket" },
                  on: { input: _vm.bucketChange },
                  model: {
                    value: _vm.selectedBucket,
                    callback: function($$v) {
                      _vm.selectedBucket = $$v
                    },
                    expression: "selectedBucket"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ap__input__desc" }, [
                  _vm._v("\n          Select the Bucket.\n        ")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ap__wrap--btn" },
              [
                _c("ap-button", {
                  attrs: {
                    disabled: _vm.disableAddSlide,
                    value: "Add slides to bucket"
                  },
                  on: { clicked: _vm.addNewSlideModal }
                }),
                _vm._v(" "),
                _c("ap-button", {
                  attrs: {
                    disabled: _vm.disableDisplaySlide,
                    value: "Display Bucket's Slides"
                  },
                  on: { clicked: _vm.displayBucketSlides }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "ap__input" },
              [
                _c("label", [_vm._v("Bucket:")]),
                _vm._v(" "),
                _c("v-select", {
                  attrs: { options: _vm.allBucketNames, label: "domain" },
                  model: {
                    value: _vm.bucketToBroadcast,
                    callback: function($$v) {
                      _vm.bucketToBroadcast = $$v
                    },
                    expression: "bucketToBroadcast"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ap__input__desc" }, [
                  _vm._v(
                    "\n          Select bucket to broadcast to all domains that use it.\n        "
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ap__wrap--btn" },
              [
                _c("ap-button", {
                  attrs: {
                    disabled: _vm.disableDomainSlides,
                    value: "Preview Domain Slides"
                  },
                  on: { clicked: _vm.displayDomainSlides }
                }),
                _vm._v(" "),
                _c("ap-button", {
                  attrs: {
                    disabled: _vm.disableDomainSlides,
                    value: "Push to WP"
                  },
                  on: { clicked: _vm.pushToWPDialog }
                })
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.displaySlides
            ? _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("tr", [
                            _c(
                              "td",
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: item.imageSrc,
                                    height: "120",
                                    width: "300"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.editSlideModal(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Replace\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.showDeleteDialog(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Delete\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  7711589
                )
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.displayDomainImages
            ? _c(
                "v-tabs",
                [
                  _vm._l(_vm.domainSliderTabs, function(item) {
                    return _c(
                      "v-tab",
                      { key: item, staticClass: "text-white" },
                      [_vm._v("\n        " + _vm._s(item) + "\n      ")]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.domainSliderTables, function(data) {
                    return _c(
                      "v-tab-item",
                      { key: data.domain },
                      [
                        _c("v-data-table", {
                          staticClass: "table-domain",
                          attrs: {
                            headers: _vm.domainHeaders,
                            items: data.domainItems,
                            "hide-default-footer": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "tr",
                                      {
                                        style: { backgroundColor: item.color }
                                      },
                                      [
                                        _c(
                                          "td",
                                          [
                                            _c("v-img", {
                                              attrs: {
                                                src: item.imageSrc,
                                                height: "120",
                                                width: "300"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.bucket) +
                                              "\n              "
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          model: {
            value: _vm.modalOpen,
            callback: function($$v) {
              _vm.modalOpen = $$v
            },
            expression: "modalOpen"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Image Modal")]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card",
                    { staticClass: "image-card" },
                    [_c("v-img", { attrs: { src: _vm.getImageSrc } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ap__input__wrap" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newImageUrl,
                            expression: "newImageUrl"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.newImageUrl },
                        on: {
                          click: _vm.changeImageUrlEvent,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newImageUrl = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ap-button", {
                        attrs: {
                          disabled: _vm.disableInsertSlide,
                          value: "Load image from URL."
                        },
                        on: { clicked: _vm.openImagePicker }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ap__input d-flex justify-space-between" },
                    [
                      _c(
                        "div",
                        [
                          _c("label", { staticClass: "card-label" }, [
                            _vm._v("Start")
                          ]),
                          _vm._v(" "),
                          _c("v-date-picker", {
                            attrs: {
                              "no-title": "",
                              "show-current": false,
                              color: "#78be20",
                              min: _vm.today
                            },
                            model: {
                              value: _vm.startDate,
                              callback: function($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("label", { staticClass: "card-label" }, [
                            _vm._v("Expiry")
                          ]),
                          _vm._v(" "),
                          _c("v-date-picker", {
                            attrs: {
                              "no-title": "",
                              "show-current": false,
                              color: "#78be20",
                              min: _vm.todayAndStart
                            },
                            model: {
                              value: _vm.expirytDate,
                              callback: function($$v) {
                                _vm.expirytDate = $$v
                              },
                              expression: "expirytDate"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("label", [_vm._v("Image Alt tag:")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.imageAltTag,
                        expression: "imageAltTag"
                      }
                    ],
                    staticClass: "image-label",
                    attrs: { type: "text" },
                    domProps: { value: _vm.imageAltTag },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.imageAltTag = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v("Image title:")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.imageTitle,
                        expression: "imageTitle"
                      }
                    ],
                    staticClass: "image-label",
                    attrs: { type: "text" },
                    domProps: { value: _vm.imageTitle },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.imageTitle = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v("Link URL:")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.linkUrl,
                        expression: "linkUrl"
                      }
                    ],
                    staticClass: "image-label",
                    attrs: { type: "text" },
                    domProps: { value: _vm.linkUrl },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.linkUrl = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("v-checkbox", {
                    attrs: {
                      disabled: _vm.openInNewTabDisable,
                      "hide-details": "",
                      color: "#78be20",
                      label: "Open in new tab."
                    },
                    model: {
                      value: _vm.openInNewTab,
                      callback: function($$v) {
                        _vm.openInNewTab = $$v
                      },
                      expression: "openInNewTab"
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v("Disclaimer Title:")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.disclaimerTitle,
                        expression: "disclaimerTitle"
                      }
                    ],
                    staticClass: "image-label",
                    attrs: { type: "text" },
                    domProps: { value: _vm.disclaimerTitle },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.disclaimerTitle = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v("Disclaimer Description:")
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.disclaimerDescription,
                        expression: "disclaimerDescription"
                      }
                    ],
                    attrs: { rows: "5", cols: "75" },
                    domProps: { value: _vm.disclaimerDescription },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.disclaimerDescription = $event.target.value
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _vm.insertNewImage
                    ? _c("ap-button", {
                        attrs: {
                          disabled: _vm.diableNewImageSelected,
                          value: "Insert slide into the bucket"
                        },
                        on: { clicked: _vm.handleImageAdd }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editCurrentImage
                    ? _c("ap-button", {
                        attrs: {
                          disabled: _vm.disableReplaceSlide,
                          value: "Update Slide"
                        },
                        on: { clicked: _vm.handleImageChange }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.closeModal }
                    },
                    [_vm._v("\n          Close\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showMigrationDialog,
            callback: function($$v) {
              _vm.showMigrationDialog = $$v
            },
            expression: "showMigrationDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Migrate Slides:")]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n        Are you sure you want to replace destination's slides?\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.migrateSlides }
                        },
                        [_vm._v("\n            Yes, Migrate\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: { click: _vm.closeMigrationDialog }
                        },
                        [_vm._v("\n            Cancel\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showConfirmationDialog,
            callback: function($$v) {
              _vm.showConfirmationDialog = $$v
            },
            expression: "showConfirmationDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Delete/Deactivate Item")]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n        Are you sure you want to delete this item?\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.deleteSlide }
                        },
                        [_vm._v("\n            Yes, Delete\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("\n            Cancel\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showUpateDialog,
            callback: function($$v) {
              _vm.showUpateDialog = $$v
            },
            expression: "showUpateDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Update Item")]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n        Are you sure you want to update this item?\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.updateSlide }
                        },
                        [_vm._v("\n            Yes, Update\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("\n            Cancel\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showPushToWPDialog,
            callback: function($$v) {
              _vm.showPushToWPDialog = $$v
            },
            expression: "showPushToWPDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Update Slides")]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n        Are you sure you want to update slides of the domain?\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.pushToWP }
                        },
                        [_vm._v("\n            Yes, Update\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("\n            Cancel\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }