var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "ap-modal",
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      },
      [
        _c("div", { staticClass: "ap-modal__message" }, [_vm._t("message")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "ap-modal__close" }, [
          _c("i", { staticClass: "fas fa-times-circle" })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }