var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__section" },
    [
      _c("ap-loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.warning,
            callback: function($$v) {
              _vm.warning = $$v
            },
            expression: "warning"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Warning\n      ")
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.warningMsg) +
                    " Are you sure you want to do this?\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1 white--text" },
                      on: {
                        click: function($event) {
                          _vm.warning = false
                        }
                      }
                    },
                    [_vm._v("\n          Cancel\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1 white--text" },
                      on: { click: _vm.migrateSites }
                    },
                    [_vm._v("\n          Migrate\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.migration
        ? _c(
            "div",
            { staticClass: "loading" },
            [
              _c(
                "v-progress-linear",
                {
                  attrs: { height: "25", color: "#78be20" },
                  model: {
                    value: _vm.percent,
                    callback: function($$v) {
                      _vm.percent = $$v
                    },
                    expression: "percent"
                  }
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.progress) + " "),
                    _vm.subprogress !== ""
                      ? _c("span", [_vm._v(_vm._s(_vm.subprogress))])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.entryprogress !== ""
                      ? _c("span", [_vm._v(_vm._s(_vm.entryprogress))])
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "ap__title" }, [_vm._v("\n    Migrate\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__item" },
        [
          _c("div", { staticClass: "ap__title--sub" }, [
            _vm._v("\n      Migrate Site\n    ")
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("ul"),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__wrap" }, [
            _c(
              "div",
              { staticClass: "ap__input" },
              [
                _c("label", [_vm._v("Site")]),
                _vm._v(" "),
                _c("v-select", {
                  attrs: { options: _vm.siteList, label: "domain" },
                  model: {
                    value: _vm.data.site,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "site", $$v)
                    },
                    expression: "data.site"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ap__input__desc" }, [
                  _vm._v("\n          Select site to migrate.\n        ")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input" }, [
              _c("label", [_vm._v("New Subdomain")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.subdomain,
                    expression: "data.subdomain"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.data.subdomain },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.data, "subdomain", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ap__input__desc" }, [
                _vm._v("\n          Defaults to original subdomain.\n        ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input" }, [
              _c("label", [_vm._v("New Blog Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.blogname,
                    expression: "data.blogname"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.data.blogname },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.data, "blogname", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ap__input__desc" }, [
                _vm._v("\n          Defaults to original blogname.\n        ")
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ap__input" },
              [
                _c("label", [_vm._v("Source Network")]),
                _vm._v(" "),
                _c("v-select", {
                  attrs: { options: _vm.albs, label: "network" },
                  on: { input: _vm.updateSiteList },
                  model: {
                    value: _vm.data.from,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "from", $$v)
                    },
                    expression: "data.from"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ap__input__desc" }, [
                  _vm._v("\n          Network migrating from.\n        ")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ap__input" },
              [
                _c("label", [_vm._v("Destination Network")]),
                _vm._v(" "),
                _c("v-select", {
                  attrs: { options: _vm.albs, label: "network" },
                  on: { input: _vm.updateDestSubdomains },
                  model: {
                    value: _vm.data.to,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "to", $$v)
                    },
                    expression: "data.to"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ap__input__desc" }, [
                  _vm._v("\n          Network migrating to.\n        ")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ap__input" },
              [
                _c("label"),
                _vm._v(" "),
                _c("v-checkbox", {
                  attrs: {
                    "hide-details": "",
                    color: "#78be20",
                    label: "Images only?"
                  },
                  model: {
                    value: _vm.data.imagesOnly,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "imagesOnly", $$v)
                    },
                    expression: "data.imagesOnly"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ap__notification", class: _vm.nclass }, [
            _vm._v("\n      " + _vm._s(_vm.message) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ap__wrap--btn" },
            [
              _c("ap-button", {
                attrs: { disabled: _vm.loading, value: "Migrate Site" },
                on: { clicked: _vm.confirmMigration }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("ap-response", { attrs: { json: _vm.output } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_c("strong", [_vm._v("Inputs")])]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Sites displayed will be based on the source network.")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Migrate sites between two of the listed networks below.")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "To duplicate a site, select the same network for source and destination, then set the new subdomain and blog name."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "A warning will pop up if a site with the same blog name or domain already exists in the destination network."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "The site will slowly populate after clicking the migrate sites button."
        )
      ]),
      _vm._v(" "),
      _c("li", [_c("strong", [_vm._v("Important")])]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          'The message "Network error while making POST request to https://site.network.com/wp-json/achilles/v1/import-db or request took longer than 30seconds to respond." is harmless and can be ignored.'
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("After migrating, we have to click the "),
        _c("strong", [_vm._v("'Flush Cache'")]),
        _vm._v(
          " button on the network we migrated the site to. You can find this in the Network Admin wp-admin -> settings -> redis panel."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Make sure no images in the source site reference another source site's media library."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }