var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__section" },
    [
      _c("div", { staticClass: "ap__title" }, [_vm._v("\n    Users\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__item" },
        [
          _c("ap-loading", { attrs: { loading: _vm.createLoading } }),
          _vm._v(" "),
          _c("div", { staticClass: "ap__title--sub" }, [
            _vm._v("\n      User Creation\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__wrap" }, [
            _c("div", { staticClass: "ap__input" }, [
              _c("label", [_vm._v("Username")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.username,
                    expression: "user.username"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.user.username },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "username", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input" }, [
              _c("label", [_vm._v("Password")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.password,
                    expression: "user.password"
                  }
                ],
                attrs: { type: "password" },
                domProps: { value: _vm.user.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "password", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input" }, [
              _c("label", [_vm._v("Verify Password")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.verfiedPassword,
                    expression: "verfiedPassword"
                  }
                ],
                attrs: { type: "password" },
                domProps: { value: _vm.verfiedPassword },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.verfiedPassword = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input" }, [
              _c("label", [_vm._v("User Email")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.email,
                    expression: "user.email"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.user.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "email", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input" }, [
              _c("label", [_vm._v("User Role")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.role,
                      expression: "user.role"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.user,
                        "role",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.roles, function(role) {
                  return _c(
                    "option",
                    { key: role, domProps: { value: role } },
                    [_vm._v("\n            " + _vm._s(role) + "\n          ")]
                  )
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "error-group" },
            _vm._l(_vm.errors, function(error) {
              return _c("div", { key: error }, [
                _c("span", { staticClass: "error" }, [_vm._v("*")]),
                _vm._v(" " + _vm._s(error) + "\n      ")
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ap__wrap--btn" },
            [
              _c("ap-button", {
                attrs: { value: "Create User", disabled: !_vm.valid },
                on: { clicked: _vm.createUser }
              }),
              _vm._v(" "),
              _c("ap-button", {
                attrs: {
                  value: "Generate Password",
                  disabled: _vm.verfiedPassword && _vm.user.password
                },
                on: {
                  clicked: function($event) {
                    return _vm.generatePassword()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__item" },
        [
          _c("div", { staticClass: "ap__title--sub" }, [
            _vm._v("\n      Modify Current Users\n    ")
          ]),
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c("ap-loading", { attrs: { loading: _vm.userLoading } }),
              _vm._v(" "),
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "white" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-text-field", {
                        attrs: {
                          "append-icon": "search",
                          label: "Search",
                          "single-line": "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            icon: "",
                            color: "#78be20",
                            disabled: _vm.userLoading
                          },
                          on: { click: _vm.updateUsers }
                        },
                        [_c("v-icon", [_vm._v("cached")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "500px" },
                      model: {
                        value: _vm.dialog,
                        callback: function($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [
                            _c("span", { staticClass: "headline" }, [
                              _vm._v("Edit User")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("v-card-text", [
                            _c("div", { staticClass: "ap__input" }, [
                              _c("label", [_vm._v("Username")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editedUser.username,
                                    expression: "editedUser.username"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.editedUser.username },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.editedUser,
                                      "username",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "ap__input" }, [
                              _c("label", [_vm._v("User Email")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editedUser.email,
                                    expression: "editedUser.email"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.editedUser.email },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.editedUser,
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "ap__input" }, [
                              _c("label", [_vm._v("User Role")]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.editedUser.role,
                                      expression: "editedUser.role"
                                    }
                                  ],
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.editedUser,
                                        "role",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.roles, function(role) {
                                  return _c(
                                    "option",
                                    { key: role, domProps: { value: role } },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(role) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "blue darken-1", text: "" },
                                  on: { click: _vm.close }
                                },
                                [
                                  _vm._v(
                                    "\n                Cancel\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "blue darken-1", text: "" },
                                  on: { click: _vm.save }
                                },
                                [
                                  _vm._v(
                                    "\n                Save\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "notification", class: _vm.nclass }, [
                _vm._v("\n        " + _vm._s(_vm.notification) + "\n      ")
              ]),
              _vm._v(" "),
              _c("v-data-table", {
                attrs: {
                  headers: _vm.userHeaders,
                  items: _vm.users,
                  search: _vm.search
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.edit",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                return _vm.editUser(item)
                              }
                            }
                          },
                          [_vm._v("\n            fa fa-pencil-alt\n          ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.delete",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteUser(item)
                              }
                            }
                          },
                          [_vm._v("\n            fa fa-trash\n          ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.modal
        ? _c(
            "ap-modal",
            {
              on: {
                close: function($event) {
                  _vm.modal = false
                }
              }
            },
            [
              _c("h2", { attrs: { slot: "message" }, slot: "message" }, [
                _vm._v("\n      " + _vm._s(_vm.modalMessage) + "\n    ")
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }