var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c("ap-loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Domain")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.filteredDomains, label: "name" },
              model: {
                value: _vm.domain,
                callback: function($$v) {
                  _vm.domain = $$v
                },
                expression: "domain"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Which domains to transfer.\n      ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Network")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.networkOptions, label: "network" },
              model: {
                value: _vm.network,
                callback: function($$v) {
                  _vm.network = $$v
                },
                expression: "network"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v(
                "\n        Which network is this domain transfering to.\n      "
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__wrap--btn" },
        [
          _c("ap-button", {
            attrs: { disabled: _vm.loading, value: "Switch Networks" },
            on: { clicked: _vm.switchNetwork }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("ap-response", { attrs: { json: _vm.output } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n    Please follow the instructions to switch site to a different network on "
      ),
      _c("b", [_vm._v("CloudFlare")]),
      _vm._v("."),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", [
      _c("li", [_vm._v("Only sites that are fully active, will show here")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Select your desired site and network and then hit Switch Network button"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Portal will create new DNS and SSL entries on Cloudflare for selected domain and network"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "You will see 2 entries for same site, one with existing network and other with new network"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Now update domain TXT records as per new Cloudflare values")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Once updated, portal cron will sync it and it will mark the new entry as green and remove previous entry from portal and also from Cloudflare"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }