var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ap__section" }, [
    _c(
      "div",
      { staticClass: "ap__item" },
      [
        _c("ap-loading", { attrs: { loading: _vm.loading } }),
        _vm._v(" "),
        _c("div", { staticClass: "ap__title--sub" }, [
          _vm._v("\n      Showroom Build and Price Configuration\n    ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__wrap--btn" },
          [
            _c("ap-button", {
              attrs: { value: _vm.currentEnv.toggledLabel },
              on: {
                clicked: function($event) {
                  return _vm.toggleEnv()
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ap__input__wrap" }, [
          _c(
            "div",
            { staticClass: "ap__input" },
            [
              _c("label", { staticClass: "ap__input__label" }, [
                _vm._v(
                  _vm._s(
                    _vm.currentEnv.value[0].toUpperCase() +
                      _vm.currentEnv.value.slice(1)
                  ) + " Networks"
                )
              ]),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  options: _vm.albs,
                  label: "network",
                  multiple: "",
                  disabled: ""
                },
                model: {
                  value: _vm.networks,
                  callback: function($$v) {
                    _vm.networks = $$v
                  },
                  expression: "networks"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ap__input__desc" }, [
                _vm._v("\n          Which networks to update.\n        ")
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "v-card",
          { staticClass: "v-card-bp" },
          [
            _c(
              "v-tabs",
              { attrs: { color: "white" } },
              [
                _vm._l(_vm.makes, function(make) {
                  return _c(
                    "v-tab",
                    {
                      key: make.id,
                      staticClass: "text-white",
                      attrs: { ripple: "" },
                      on: {
                        click: function($event) {
                          return _vm.setMake(make)
                        }
                      }
                    },
                    [_vm._v("\n          " + _vm._s(make.label) + "\n        ")]
                  )
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "bp-config-link",
                    attrs: {
                      target: "_blank",
                      href:
                        "https://trader.atlassian.net/wiki/spaces/TAD/pages/3694133290/Showroom+Build+Price+Configuration+Guide"
                    }
                  },
                  [_vm._v("Configuration Guide")]
                ),
                _vm._v(" "),
                _vm._l(_vm.makes, function(make) {
                  return _c(
                    "v-tab-item",
                    { key: make.id },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": "search",
                              label: "Search",
                              "single-line": "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          }),
                          _vm._v(" "),
                          _c("ap-button", {
                            attrs: { value: "Add New URL" },
                            on: {
                              clicked: function($event) {
                                return _vm.addItem()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.currentItems.length > 0
                        ? _c("showroomBPTable", {
                            attrs: {
                              "active-items": _vm.currentItems,
                              "active-make": _vm.activeTab.label,
                              search: _vm.search,
                              makes: _vm.makes.map(function(make) {
                                return make.label
                              })
                            },
                            on: { edit: _vm.editItem, delete: _vm.deleteItem }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ap__table__footer" },
                        [
                          _c("ap-button", {
                            attrs: {
                              value: "Submit Configuration",
                              disabled: !_vm.itemsModified
                            },
                            on: {
                              clicked: function($event) {
                                return _vm.openDialog()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("ap-button", {
                            attrs: {
                              disabled: _vm.loading,
                              value: "Download History"
                            },
                            on: { clicked: _vm.downloadBPConfigHistory }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ap__notification",
                              class: _vm.updated
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.message) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "500" },
                          model: {
                            value: _vm.dialog,
                            callback: function($$v) {
                              _vm.dialog = $$v
                            },
                            expression: "dialog"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", { staticClass: "headline" }, [
                                _vm._v(
                                  "\n                Confirmation\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-card-text", [
                                _vm._v(
                                  "\n                Do you have approval to update the Build and Price configuration for the selected " +
                                    _vm._s(_vm.currentEnv.value) +
                                    " networks?\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "green darken-1",
                                        text: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateBPConfig(true)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Yes\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "green darken-1",
                                        text: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateBPConfig(false)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  No\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "500" },
                          model: {
                            value: _vm.alert.visible,
                            callback: function($$v) {
                              _vm.$set(_vm.alert, "visible", $$v)
                            },
                            expression: "alert.visible"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "text-h5 grey lighten-2" },
                                [
                                  _vm._v(
                                    "\n                Warning!\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "my-2",
                                      attrs: { type: "info", dense: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.alert.message) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "error", text: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.alert.visible = false
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Close\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }