var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__section" },
    [
      _c("div", { staticClass: "ap__title" }, [_vm._v("\n    Mass Query\n  ")]),
      _vm._v(" "),
      _c(
        "v-tabs",
        { attrs: { color: "white" } },
        _vm._l(_vm.tabs, function(tab) {
          return _c(
            "v-tab",
            {
              key: tab.label,
              attrs: { ripple: "" },
              on: {
                click: function($event) {
                  return _vm.changeActiveTab(tab)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(tab.label) + "\n    ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__tab__content" },
        [_c("keep-alive", [_c(_vm.activeTab, { tag: "component" })], 1)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }