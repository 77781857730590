var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c("ap-loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("p", [
        _vm._v("The following are a list of actions available in this tab.")
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("Account ID")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.accountId,
                expression: "accountId"
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.accountId },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.accountId = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__desc" }, [
            _vm._v("\n        Enter Account ID (Unique/String)\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("Domain")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.domains,
                expression: "domains"
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.domains },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.domains = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__desc" }, [
            _vm._v("\n        Domain to request ssl for.\n      ")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Network")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.prodALBs, label: "network" },
              on: {
                input: function($event) {
                  return _vm.$store.dispatch("updateGroups")
                }
              },
              model: {
                value: _vm.network,
                callback: function($$v) {
                  _vm.network = $$v
                },
                expression: "network"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v(
                "\n        Which production network does this domain belong to.\n      "
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__wrap--btn" },
        [
          _c("ap-button", {
            attrs: { disabled: _vm.disabled, value: "Request SSL" },
            on: { clicked: _vm.requestSSL }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("ap-response", { attrs: { json: _vm.output } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v("Request ssl for a domain and assign it to its network.")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("See the status of a domain's apex and www SSL certificates.")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Delete an expired / incorrect domain. Available when status = TIMED_OUT."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Re-request an expired ssl request. Available when status = TIMED_OUT."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Run the cron that updates all pending certificate statuses. Available on pending table."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Run the cron that updates all Cloudfront values on successful domains. Only updates this value if the group SSL has been ISSUED."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }