var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "v-data-table-bp",
    attrs: {
      headers: _vm.headers,
      items: _vm.activeItems,
      search: _vm.search,
      "no-data-text": "No data available"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function(ref) {
          var items = ref.items
          return [
            _c(
              "tbody",
              _vm._l(items, function(item, index) {
                return _c(
                  "tr",
                  { key: index, class: { editable: !item.readonly } },
                  [
                    _c(
                      "td",
                      { staticClass: "modeltd" },
                      [
                        _c("v-select", {
                          attrs: {
                            options: _vm.filteredModels(item.model),
                            disabled: item.readonly,
                            dense: ""
                          },
                          model: {
                            value: item.model,
                            callback: function($$v) {
                              _vm.$set(item, "model", $$v)
                            },
                            expression: "item.model"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "yeartd" },
                      [
                        _c("v-select", {
                          attrs: {
                            options: _vm.filteredYears(item.model, item.year),
                            label: "Select Year",
                            disabled: item.readonly,
                            multiple: "",
                            taggable: "",
                            sortable: ""
                          },
                          model: {
                            value: item.year,
                            callback: function($$v) {
                              _vm.$set(item, "year", $$v)
                            },
                            expression: "item.year"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "patterntd" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.pattern,
                            expression: "item.pattern"
                          }
                        ],
                        attrs: { type: "text", disabled: item.readonly },
                        domProps: { value: item.pattern },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "pattern", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.segment1,
                            expression: "item.segment1"
                          }
                        ],
                        attrs: { type: "text", disabled: item.readonly },
                        domProps: { value: item.segment1 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "segment1", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "mappedmodeltd" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.mappedModel,
                            expression: "item.mappedModel"
                          }
                        ],
                        attrs: { type: "text", disabled: item.readonly },
                        domProps: { value: item.mappedModel },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "mappedModel", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.segment2,
                            expression: "item.segment2"
                          }
                        ],
                        attrs: { type: "text", disabled: item.readonly },
                        domProps: { value: item.segment2 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "segment2", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.dcIncentivesURL,
                            expression: "item.dcIncentivesURL"
                          }
                        ],
                        attrs: { type: "text", disabled: item.readonly },
                        domProps: { value: item.dcIncentivesURL },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              item,
                              "dcIncentivesURL",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "dctypetd" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.dctype,
                            expression: "item.dctype"
                          }
                        ],
                        attrs: { type: "text", disabled: item.readonly },
                        domProps: { value: item.dctype },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "dctype", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.intcmp,
                            expression: "item.intcmp"
                          }
                        ],
                        attrs: { type: "text", disabled: item.readonly },
                        domProps: { value: item.intcmp },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "intcmp", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "actiontd" }, [
                      _c("span", [
                        _c("i", {
                          class: [
                            "icon",
                            item.readonly ? "fa fa-pencil-alt" : "fa fa-check"
                          ],
                          attrs: { area: "hidden", title: "Edit" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("edit", item.id)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _c("i", {
                          staticClass: "icon fa fa-minus-circle",
                          attrs: { area: "hidden", title: "Delete" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("delete", item.id)
                            }
                          }
                        })
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }