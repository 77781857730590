var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__section" },
    [
      _c("ap-loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("div", { staticClass: "ap__title" }, [_vm._v("\n    Selenium\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__item" },
        [
          _c("div", { staticClass: "ap__title--sub" }, [
            _vm._v("\n      Build Test\n    ")
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "- Build a selenium job on jenkins and have it log the output in a Jira sub-task."
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__wrap" }, [
            _c("div", { staticClass: "ap__input__wrap" }, [
              _c("div", { staticClass: "ap__input" }, [
                _c("label", [_vm._v("URL")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.url,
                      expression: "data.url"
                    }
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.data.url },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.data, "url", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ap__input__desc" }, [
                  _vm._v(
                    "\n            URL to run selenium test on.\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ap__input" }, [
                _c("label", [_vm._v("Test")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.test,
                        expression: "data.test"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.data,
                          "test",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.jira.tests, function(test) {
                    return _c(
                      "option",
                      { key: test.name, domProps: { value: test.command } },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(test.name) +
                            "\n            "
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ap__input__desc" }, [
                  _vm._v(
                    "\n            Which test to run the url on.\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ap__input" }, [
                _c("label", [_vm._v("Story")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.story,
                      expression: "data.story"
                    }
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.data.story },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.data, "story", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ap__input__desc" }, [
                  _vm._v(
                    "\n            Name of story to assign sub-task to.\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ap__input" }, [
                _c("label", [_vm._v("Assignee")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.assignee,
                        expression: "data.assignee"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.data,
                          "assignee",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.jira.users, function(value, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: key } },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(value.display) +
                            "\n            "
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ap__input__desc" }, [
                  _vm._v(
                    "\n            User to assign sub-task to.\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ap__input" }, [
                _c("label", [_vm._v("Owner")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.owner,
                        expression: "data.owner"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.data,
                          "owner",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.jira.users, function(value, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: key } },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(value.display) +
                            "\n            "
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ap__input__desc" }, [
                  _vm._v("\n            Owner of the sub-task.\n          ")
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ap__notification", class: _vm.nclass }, [
            _vm._v("\n      " + _vm._s(_vm.notification) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ap__wrap--btn" },
            [
              _c("ap-button", {
                attrs: { disabled: _vm.build.disabled, value: "Build Job" },
                on: { clicked: _vm.buildFn }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("ap-response", { attrs: { json: _vm.build.output } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }