var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "validate" },
    [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selected,
              expression: "selected"
            }
          ],
          staticClass: "validate__select",
          attrs: { multiple: "" },
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selected = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        _vm._l(_vm.files, function(file) {
          return _c("option", { key: file, domProps: { value: file } }, [
            _vm._v("\n      " + _vm._s(file) + "\n    ")
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("ap-button", {
        attrs: { disabled: _vm.disabled, value: "Validate File(s)" },
        on: { clicked: _vm.validate }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "ap__label" }, [_vm._v("\n    Response\n  ")]),
      _vm._v(" "),
      _c("convertus-output", {
        attrs: {
          section: {
            disabled: _vm.disabled,
            output: _vm.output,
            results: _vm.results
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }