var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__section" },
    [
      _c("div", { staticClass: "ap__label" }, [
        _vm._v("\n    Add New Profile\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.profile.name,
                expression: "profile.name"
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.profile.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.profile, "name", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__desc" }, [
            _vm._v("\n        Name of new check profile.\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("Interval in Minutes")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.profile.interval,
                expression: "profile.interval",
                modifiers: { number: true }
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.profile.interval },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.profile, "interval", _vm._n($event.target.value))
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__desc" }, [
            _vm._v("\n        How often this check is run.\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("Threshold in Seconds")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.profile.threshold,
                expression: "profile.threshold",
                modifiers: { number: true }
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.profile.threshold },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.profile, "threshold", _vm._n($event.target.value))
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__desc" }, [
            _vm._v("\n        Timeout of check.\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ap__input" }, [
          _c("label", [_vm._v("Sensitivity")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.profile.sens,
                expression: "profile.sens",
                modifiers: { number: true }
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.profile.sens },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.profile, "sens", _vm._n($event.target.value))
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ap__input__desc" }, [
            _vm._v(
              "\n        # additional servers to check when status changes.\n      "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("v-checkbox", {
              attrs: {
                "hide-details": "",
                color: "#78be20",
                label: "Follow redirects?"
              },
              model: {
                value: _vm.profile.follow,
                callback: function($$v) {
                  _vm.$set(_vm.profile, "follow", $$v)
                },
                expression: "profile.follow"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("v-checkbox", {
              attrs: { "hide-details": "", color: "#78be20", label: "Enable?" },
              model: {
                value: _vm.profile.enabled,
                callback: function($$v) {
                  _vm.$set(_vm.profile, "enabled", $$v)
                },
                expression: "profile.enabled"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__wrap--btn" },
        [
          _c("ap-button", {
            attrs: { disabled: _vm.loading, value: "Save Profile" },
            on: { clicked: _vm.save }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("ap-loading", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.nodepings,
              search: _vm.search
            },
            scopedSlots: _vm._u([
              {
                key: "item.follow",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.booleanToText(item.follow)) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.sites",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.nodepingObj[item.name]) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ap__table__footer" },
            [
              _c("ap-button", {
                attrs: { disabled: _vm.loading, value: "Refresh" },
                on: { clicked: _vm.update }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "ap__notification", class: _vm.updated }, [
        _vm._v("\n    " + _vm._s(_vm.message) + "\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "ap__label pt-5" }, [
        _vm._v("\n      Field Definitions\n    ")
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("strong", [_vm._v("Interval")]),
          _vm._v(
            " - Defaults to 15 - How often this check runs in minutes. Can be any integer starting at 1 for one minute checks. Once a day is 1440."
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Threshold")]),
          _vm._v(
            " - The timeout for this check in seconds, defaults to 5 for a five second timeout. Can be any integer starting at 1."
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Sensitivity")]),
          _vm._v(
            " - Number of rechecks before this check is considered 'down' or 'up'. Defaults to 2 (2 rechecks means it will be checked by 3 different servers). Rechecks are run immediately, not based on the interval and happen when a status change occurs."
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Follow")]),
          _vm._v(" - If true, the check will follow up to four redirects.")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }