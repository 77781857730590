var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "login" } }, [
    _c(
      "div",
      { staticClass: "ap__box" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "ap__box__input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.username,
                expression: "username"
              }
            ],
            attrs: { type: "text", placeholder: "Username" },
            domProps: { value: _vm.username },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.username = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ap__box__input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password"
              }
            ],
            attrs: { type: "password", placeholder: "Password" },
            domProps: { value: _vm.password },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "ap__button",
            class: { disabled: _vm.disabled },
            attrs: { id: "login_btn" },
            on: { click: _vm.login }
          },
          [_vm._v("\n      Log in\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "ap__notification",
            class: _vm.messageClass + "--text"
          },
          [_vm._v("\n      " + _vm._s(_vm.message) + "\n    ")]
        ),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("ap-version", { staticClass: "loginPageText" })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          width: "275px",
          src: "/assets/images/logo.png",
          alt: "Convertus Logo"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loginPageText" }, [
      _c(
        "a",
        {
          attrs: {
            href:
              "mailto:support@convertus.com?subject=Forgotten Password For Achilles Portal"
          }
        },
        [_vm._v("Forgot Your Password?")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }