var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c("ap-loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The above will re-group all your url SSLs into the new network in the Achille's AWS infrastructure, and point all the distributions to the new network."
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ap__input__wrap" }, [
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Domain(s)")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                options: _vm.filteredDomains,
                label: "name",
                multiple: ""
              },
              model: {
                value: _vm.domains,
                callback: function($$v) {
                  _vm.domains = $$v
                },
                expression: "domains"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v("\n        Which domains to transfer.\n      ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ap__input" },
          [
            _c("label", [_vm._v("Network")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: { options: _vm.prodALBs, label: "network" },
              model: {
                value: _vm.network,
                callback: function($$v) {
                  _vm.network = $$v
                },
                expression: "network"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ap__input__desc" }, [
              _vm._v(
                "\n        Which network is this domain transfering to.\n      "
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ap__wrap--btn" },
        [
          _c("ap-button", {
            attrs: { disabled: _vm.loading, value: "Switch Networks" },
            on: { clicked: _vm.switchNetwork }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("ap-response", { attrs: { json: _vm.output } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n    Only domains that "),
      _c("b", [_vm._v("exist in production networks")]),
      _vm._v(" and have a "),
      _c("b", [_vm._v("cloudfront distribution")]),
      _vm._v(" display here."),
      _c("br"),
      _vm._v(
        "\n    The following explains how this tool is used and its pre-requisites.\n  "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", [
      _c("li", [
        _vm._v(
          "Make sure your site exists on the network your transfering it to."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Make sure your site ran the find and replace to map the old url to the new url ( live url )."
        )
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Select the live url(s) from the domain(s) list.")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Select the network you'd like to transfer these sites over to.")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Run the switch networks button and read the output for any error(s)."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }