var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__section" },
    [
      _c("div", { staticClass: "ap__title" }, [_vm._v("\n      DNS\n    ")]),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("ap-loading", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: { headers: _vm.headers, items: _vm.dns, search: _vm.search },
            scopedSlots: _vm._u([
              {
                key: "item.accountId",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(item.accountId || "N/A") +
                        "\n        "
                    )
                  ]
                }
              },
              {
                key: "item.wwwDomainStatus",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      { class: _vm.getColor(item.wwwDomainStatus) + "--text" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getStatusText(item.wwwDomainStatus)) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.lastDomainStatusCheckAt",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("dateFormat")(item.lastDomainStatusCheckAt)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ap__table__footer" },
            [
              _c("ap-button", {
                attrs: { disabled: _vm.loading, value: "Refresh" },
                on: { clicked: _vm.update }
              }),
              _vm._v(" "),
              _c("ap-button", {
                attrs: { disabled: _vm.loading, value: "Download CSV" },
                on: { clicked: _vm.csv }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ap__notification", class: _vm.updated }, [
        _vm._v("\n      " + _vm._s(_vm.message) + "\n    ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }