var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ap__section" },
    [
      _c("div", { staticClass: "ap__title" }, [_vm._v("\n    Networks\n  ")]),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("ap-loading", { attrs: { loading: _vm.loading } }),
          _vm._v(" "),
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.albs,
              search: _vm.search
            },
            scopedSlots: _vm._u([
              {
                key: "item.domain",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "http://" + item.domain,
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.domain) + "\n        "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.status",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      {
                        style: "color: " + _vm.getColor(item.status),
                        attrs: { dark: "", small: "" }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.status) + "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ap__table__footer" },
            [
              _c("ap-button", {
                attrs: { disabled: _vm.loading, value: "Refresh" },
                on: { clicked: _vm.update }
              }),
              _vm._v(" "),
              _c("ap-button", {
                attrs: { disabled: _vm.loading, value: "Download CSV" },
                on: { clicked: _vm.csv }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ap__notification", class: _vm.updated }, [
        _vm._v("\n    " + _vm._s(_vm.message) + "\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }