var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload" },
    [
      _c("input", {
        attrs: { type: "file", multiple: "" },
        on: { change: _vm.updateFiles }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "upload__response", class: _vm.uploadClass }, [
        _vm._v("\n    " + _vm._s(_vm.response) + "\n  ")
      ]),
      _vm._v(" "),
      _c("ap-button", {
        attrs: { value: "Upload", disabled: _vm.disabled },
        on: { clicked: _vm.upload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }